import {
    NotificationTarget,
    NotificationViewModel,
} from '@/api';
import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';

const api = apiInstanceFactory();

class NotificationsService extends ApiBase {
    public async getActiveNotifications(): Promise<NotificationViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.notificationsGetActiveNotificationsList({
            target: NotificationTarget.Shipping,
        }), { suppressErrors: true });
    }

    public async markNotificationAsSeen(notificationId: string): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.notificationsMarkNotificationAsSeenCreate({
            notificationId,
        }), { suppressErrors: true });
    }
}

export default new NotificationsService();

import { userStore } from '@/store/user/user.store';
import { createRouter, createWebHistory, RouteMeta } from 'vue-router';
import { RouteNames, routes } from './router.routes';
import { isProdMode } from '@/infrastructure/environment';
import serverHubClient from '@/signalR/serverHub.client';
import dictionary from '@/core/dictionary/dictionary';

export interface RouteMetaExtended extends RouteMeta {
    allowAnonymous?: boolean;
}

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach(async(to, _, next) => {
    const isLoggedIn = userStore.isLoggedIn;

    if ((to.name === RouteNames.AUTH_LOGIN || 
         to.name === RouteNames.AUTH_FORGOT_PASSWORD ||
         to.name === RouteNames.AUTH_RESET_PASSWORD) && isLoggedIn.value) {
        next({ name: RouteNames.SHIPMENT, params: to.params, query: to.query });
        return;
    } else if (!to.meta.allowAnonymous && !isLoggedIn.value) {
        next({ name: RouteNames.AUTH_LOGIN, params: to.params, query: { to: to.path, ...to.query } });
        return;
    }

    next();
});

router.afterEach((to, _) => {
    // const meta = to.meta as RouteMetaExtended;

    const title = to.name?.toString();

    if (title) {
        const pageTitleKey = 'Page.Title.' + title;
        if (dictionary.has(pageTitleKey)) {
            const pageTitle = dictionary.get(pageTitleKey);
            document.title = `${pageTitle} - ${window.serverContext.businessEntity.name}`;
        } else {
            document.title = `${title} - ${window.serverContext.businessEntity.name}`;
        }
        
        userStore.isLoggedIn.value && serverHubClient.activityUpdate(title);
    } else {
        document.title = window.serverContext.businessEntity.name;
    }
});

const isProduction = isProdMode();
if (isProduction) {
    router.onError((e, to) => {
        if (e && typeof e.message === 'string') {
            if (e.message.startsWith('Failed to fetch dynamically imported module')) {
                // After a deployment, users must re-fresh the page to update the async components locations
                window.location.reload();
            }
        }
    });
}

console.log(`IMENDO APP ENVIRONMENT: ${isProduction ? 'PRODUCTION' : 'DEVELOPMENT'}`);

export default router;

import { App, defineAsyncComponent } from 'vue';

const CallToAction = defineAsyncComponent(() => import('./buttons/CallToAction.vue'));
const ToggleButton = defineAsyncComponent(() => import('./buttons/ToggleButton.vue'));
const SmartParagraph = defineAsyncComponent(() => import('./generic/SmartParagraph.vue'));
const Spinner = defineAsyncComponent(() => import('./loading-spinners/Spinner.vue'));

export default function config(app: App): void {
    app.component('CallToAction', CallToAction);
    app.component('ToggleButton', ToggleButton);
    app.component('SmartParagraph', SmartParagraph);
    app.component('Spinner', Spinner);
}

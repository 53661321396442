import { ConsumerServerContextViewObject } from '@/api';

declare global {
    interface Window {
        domainName: string;
        appInfo?: any;
        serverContext: ConsumerServerContextViewObject
    }
}

declare global {
    interface ImportMetaEnv {
        readonly VITE_API_BASE: string
        readonly VITE_GOOGLE_APIKEY: string
        readonly VITE_GOOGLE_MAPID: string
    }
}

window.domainName = window.location.pathname.split('/')[1];

export function isDevMode(): boolean {
    return !isProdMode();
}

export function isProdMode(): boolean {
    // return process.env.NODE_ENV === 'production';
    return import.meta.env.PROD;
}

export function getPlatform(): 'android' | 'ios' | 'web' {
    return 'web';
}
import { BusinessEntitySettingsViewObject, CustomerAddressViewObject, DeliveryTaskViewObject, NotificationViewObject } from './../api/index';
import { defaultReconnectPolicy, SignalRClient } from '@/core/signalr/signalr.client';
import { isDevMode } from '@/infrastructure/environment';
import { LogLevel } from '@microsoft/signalr';
import bus from '@/core/bus';

const SIGNALR_URL = import.meta.env.VITE_API_BASE + '/hub';
const SIGNALR_LOGLEVEL = isDevMode() ? LogLevel.Information : LogLevel.Error;

export class ServerHub extends SignalRClient {
    constructor() {
        super(SIGNALR_URL, 'Web', SIGNALR_LOGLEVEL, defaultReconnectPolicy, false);

        // TASKS
        this.addEvent('TaskUpdated', (task: DeliveryTaskViewObject) => {
            bus.emit('TaskUpdated', task);
        });
                
        this.addEvent('CustomerAddressUpdated', (address: CustomerAddressViewObject) => {
            bus.emit('CustomerAddressUpdated', address);
        });

        this.addEvent('CustomerAddressDeleted', (customerId: string, addressId: number) => {
            bus.emit('CustomerAddressDeleted', customerId, addressId);
        });

        this.addEvent('BusinessEntitySettingsUpdated', (settings: BusinessEntitySettingsViewObject) => {
            bus.emit('BusinessEntitySettingsUpdated', settings);
        });

        // NOTIFICATIONS
        this.addEvent('NotificationCreated', (notification: NotificationViewObject) => {
            bus.emit('NotificationCreated', notification);
        });
        
        this.addEvent('NotificationUpdated', (notification: NotificationViewObject) => {
            bus.emit('NotificationUpdated', notification);
        });

        this.addEvent('NotificationsUpdated', (notifications: NotificationViewObject[]) => {
            bus.emit('NotificationsUpdated', notifications);
        });
                        
        this.addEvent('NotificationDeleted', (notificationId: string) => {
            bus.emit('NotificationDeleted', notificationId);
        });
    }

    public async activityUpdate(activity: string): Promise<void> {
        await this.connection.send('ActivityUpdate', activity);
    }

    public async toggleIsTyping(chatId: string | null | undefined, members: string[]): Promise<void> {
        await this.connection.send('ToggleIsTyping', chatId, members);
    }
}

export default new ServerHub();

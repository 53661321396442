import { DictionaryLabelsViewModel, DictionaryLanguagesViewModel, MarkLabelAsMissingRequest} from '@/api';
import { ApiBase } from '@/core/api/api.base';
import { apiInstanceFactory } from '@/core/api/api.factory';

const api = apiInstanceFactory();

class DictionaryService extends ApiBase {
    public async getSupportedLanguages(): Promise<DictionaryLanguagesViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryGetSupportedLanguagesList());
    }

    public async markLabelAsMissingRequest(request: MarkLabelAsMissingRequest): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryMarkLabelAsMissingCreate(request));
    }

    public async getDictionaryLabelsForArea(area?: string): Promise<DictionaryLabelsViewModel  | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryGetDictionaryLabelsForAreaList({
            area,
        }));
    }
}

export default new DictionaryService();

import type { Config } from 'tailwindcss';

export default {
    content: ['./src/**/*.vue'],
    safelist: [
        {
            pattern: /col-span-(1|2|3|4|5|6|7|8|9|10|11|12)/,
        },
    ],
    mode: 'jit',
    theme: {
        fontSize: {
            10: '1rem',
            11: '1.1rem',
            12: '1.2rem',
            13: '1.3rem',
            14: '1.4rem',
            16: '1.6rem',
            18: '1.8rem',
            20: '2rem',
            24: '2.4rem',
            32: '3.2rem',
            38: '3.8rem',
            42: '4.2rem',
            56: '5.6rem',
            80: '8rem',
            96: '9.6rem',
        },
        screens: {
            xs: '0',
            sm: '640px',
            md: '768px',
            lg: '1025px',
            xl: '1300px',
            xxl: '1300px',
        },
        zIndex: {
            menu: '1000',
            leftMenu: '1000',
            rightMenu: '1000',
            topMenu: '1500',
            dialog: '2000',
            confirmationDialog: '3000',
            spinner: '20000',
        },
        extend: {
            colors: {
                blue: {
                    dark: '#118ab2',
                    primary: '#118ab2',
                },
                green: {
                    light: '#249269',
                    dark: '#15803d',
                },
                red: {
                    light: '#ED217C',
                    dark: '#DC2626',
                },
                yellow: {
                    dark: '#FBBF24',
                },
                text: '#000',
                border: '#a1a1aa',
                background: '#e5e7eb',
                elements: '#fff',
                backdrop: '#1a1f33',
                modalBackground: '#fff',
            },
            maxWidth: {
                sm: '32rem',
                md: '48rem',
                lg: '60rem',
            },
            margin: {
                15: '3.75rem',
                50: '5rem',
                80: '8rem',
                90: '9rem',
                95: '9.5rem',
                100: '10rem',
            },
            padding: {
                15: '1.5rem',
                50: '5rem',
                80: '8rem',
            },
        },
    },
    plugins: [],
} satisfies Config;
<template>
    <div class="bg-gray-200 web__app__layout">
        <main>
            <RouterView v-slot="{ Component }">
                <Transition
                    mode="out-in"
                    appear
                    :enter-active-class="enterActiveClass"
                    :leave-active-class="leaveActiveClass">
                    <component :is="Component"/>
                </Transition>
            </RouterView>
        </main>

        <footer class="py-10 md:mx-auto">
            <p class="hidden md:block font-bold text-14">
                {{ businessEntity.name }} - {{ businessEntity.address.street }} - {{ businessEntity.address.postalCode }} {{ businessEntity.address.city }} - Tlf. {{ businessEntity.phone }}
            </p>
            <div class="md:hidden p-10 font-bold bg-white text-14">
                <p class="text-16">
                    <DLabel :label="$translate('Footer.Contact')"/>
                </p>
                <p>
                    {{ businessEntity.name }}
                </p>
                <p>
                    {{ businessEntity.address.street }}
                </p>
                <p>
                    {{ businessEntity.address.postalCode }} {{ businessEntity.address.city }}
                </p>
                <p>
                    Tlf. {{ businessEntity.phone }}
                </p>
            </div>
        </footer>
    </div>

    <ModalHost/>
    <NotificationsHost/>
    <ProgressBar/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAnimation } from '@/core/animation/animateComposable';

import ModalHost from '@/core/dialog/ModalHost.vue';
import ProgressBar from '@/core/loading/ProgressBar.vue';
import NotificationsHost from '@/core/notifications/NotificationsHost.vue';

export default defineComponent({
    name: 'App',
    components: {
        ModalHost,
        NotificationsHost,
        ProgressBar,
    },
    setup() {
        const { enterActiveClass, leaveActiveClass } = useAnimation({ type: 'fade' });

        return {
            enterActiveClass,
            leaveActiveClass,

            businessEntity: window.serverContext.businessEntity,
        };
    },
});
</script>

<style>
#app {
    position: relative;

    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}
</style>

<style lang="scss" scoped>
.web__app__layout {
    position: relative;

    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    main {
        position: relative;

        display: flex;
        flex-direction: column;

        flex: 1 0 auto;
    }
}

</style>

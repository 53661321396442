import { DashboardRouteAreaAccess } from '@/api';
import { RouteLocationNormalized, RouteMeta, RouteRecordRaw } from 'vue-router';

export interface RouteMetaExtended extends RouteMeta {
    allowAnonymous?: boolean;
    area: DashboardRouteAreaAccess;
}

export enum RouteNames {
    AUTH = 'AUTH',
    AUTH_LOGIN = 'AUTH_LOGIN',
    AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD',
    AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD',
    HOME = 'HOME',
    DASHBOARD = 'DASHBOARD',
    SHIPMENT = 'SHIPMENT',
    SHIPMENT_ORDER = 'SHIPMENT_ORDER',
    SHIPMENT_CONTACT_BOOK = 'SHIPMENT_CONTACT_BOOK',
    SHIPMENT_ORDER_HISTORY = 'SHIPMENT_ORDER_HISTORY',
    RENTAL = 'RENTAL',
    RENTAL_ORDER = 'RENTAL_ORDER',
    RENTAL_ORDER_HISTORY = 'RENTAL_ORDER_HISTORY',
}

const createAuthenticatedPath = (...paths: string[]) => {
    return `/:businessEntity:/${paths.join('/')}`;
};

export const routes: Array<RouteRecordRaw> = [
    {
        path: createAuthenticatedPath(''),
        name: RouteNames.HOME,
        component: () => import('@/pages/Index.vue'),
        redirect: {
            name: RouteNames.SHIPMENT_ORDER,
        },
        children: [
            {
                path: createAuthenticatedPath('shipment'),
                name: RouteNames.SHIPMENT,
                component: () => import('@/pages/shipment/Index.vue'),
                redirect: { name: RouteNames.SHIPMENT_ORDER },
                props: (route: RouteLocationNormalized) => ({
                    businessEntity: route.params.businessEntity,
                }),
                children: [
                    {
                        path: createAuthenticatedPath('shipment', 'order'),
                        name: RouteNames.SHIPMENT_ORDER,
                        component: () => import('@/pages/shipment/order/Order.vue'),
                        props: (route: RouteLocationNormalized) => ({
                            businessEntity: route.params.businessEntity,
                        }),
                    },
                    {
                        path: createAuthenticatedPath('shipment', 'contact-book'),
                        name: RouteNames.SHIPMENT_CONTACT_BOOK,
                        component: () => import('@/pages/shipment/contact-book/ContactBook.vue'),
                        props: (route: RouteLocationNormalized) => ({
                            businessEntity: route.params.businessEntity,
                        }),
                    },
                    {
                        path: createAuthenticatedPath('shipment', 'order-history'),
                        name: RouteNames.SHIPMENT_ORDER_HISTORY,
                        component: () => import('@/pages/shipment/order-history/OrderHistory.vue'),
                        props: (route: RouteLocationNormalized) => ({
                            businessEntity: route.params.businessEntity,
                        }),
                    }],
            },
            {
                path: createAuthenticatedPath('rental', 'order'),
                name: RouteNames.RENTAL,
                component: () => import('@/pages/rental/Index.vue'),
                props: (route: RouteLocationNormalized) => ({
                    businessEntity: route.params.businessEntity,
                }),
                children: [
                    {
                        path: createAuthenticatedPath('rental', 'order'),
                        name: RouteNames.RENTAL_ORDER,
                        component: () => import('@/pages/rental/order/Order.vue'),
                        props: (route: RouteLocationNormalized) => ({
                            businessEntity: route.params.businessEntity,
                        }),
                    },
                    {
                        path: createAuthenticatedPath('rental', 'order-history'),
                        name: RouteNames.RENTAL_ORDER_HISTORY,
                        component: () => import('@/pages/rental/order-history/OrderHistory.vue'),
                        props: (route: RouteLocationNormalized) => ({
                            businessEntity: route.params.businessEntity,
                        }),
                    },
                ],
            },
        ],
    },
    {
        path: createAuthenticatedPath('auth'),
        name: RouteNames.AUTH,
        component: () => import('@/pages/login/LoginPage.vue'),
        props: (route: RouteLocationNormalized) => ({
            businessEntity: route.params.businessEntity,
        }),
        meta: {
            allowAnonymous: true,
        },
        children: [
            {
                path: createAuthenticatedPath('auth', 'login'),
                name: RouteNames.AUTH_LOGIN,
                component: () => import('@/pages/login/LoginForm.vue'),
                props: (route: RouteLocationNormalized) => ({
                    businessEntity: route.params.businessEntity,
                }),
                meta: {
                    allowAnonymous: true,
                },
            },
            {
                path: createAuthenticatedPath('auth', 'forgot-password'),
                name: RouteNames.AUTH_FORGOT_PASSWORD,
                component: () => import('@/pages/login/ForgotPasswordForm.vue'),
                props: (route: RouteLocationNormalized) => ({
                    businessEntity: route.params.businessEntity,
                }),
                meta: {
                    allowAnonymous: true,
                },
            },
            {
                path: createAuthenticatedPath('auth', 'reset-password'),
                name: RouteNames.AUTH_RESET_PASSWORD,
                component: () => import('@/pages/login/ResetPasswordForm.vue'),
                props: (route: RouteLocationNormalized) => ({
                    businessEntity: route.params.businessEntity,
                    token: route.query.token,
                }),
                meta: {
                    allowAnonymous: true,
                },
            },
        ],
    },
    {
        path: createAuthenticatedPath('login'),
        redirect: {
            name: RouteNames.AUTH_LOGIN,
        },
        meta: {
            allowAnonymous: true,
        },
    },
    {
        path: '/:businessEntity:/:pathMatch(.*)*',
        component: () => import('@/pages/404.vue'),
        meta: {
            allowAnonymous: true,
        },
    },
];

import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';
import { ConsumerServerContextViewObject } from '@/api';

const api = apiInstanceFactory();

class BusinessEntityService extends ApiBase {
    public async getServerContext(): Promise<ConsumerServerContextViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetServerContextList());
    }
}

export default new BusinessEntityService();
